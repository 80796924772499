import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import Bio from './bio'

function DrawCourse(props) {
  let course = props.course
  let instructor = course.instructor
  let course_id = course.name.toLowerCase().replace(/ /g,"-")
  let instructor_id = instructor.name.toLowerCase().replace(/ /g,"-")

  return (
    <>
      <div className="col-4 col-6-medium col-12-small course" id={course_id}>

        <div className="card">
          <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/courses/" + course.image} alt={course.name} className="course"/>
          <h1>{course.name}</h1>
          <h2><Link to={"/team#" + instructor_id}>{instructor.displayName}</Link> ({instructor.location})</h2>

          <Bio bio={course.description}/>
          
          <div className="link">
            <Link to="/apply" state={{ course }} className="button">Sign up for this course</Link>
          </div>
        </div>

        <div className="instructor-avatar">
          <Link to={"/team#" + instructor_id}>
            <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + instructor.avatar} alt={instructor.displayName} className="avatar-small"/>
          </Link>
        </div>

      </div>
    </>
  )
}

function Courses(props) {

  setTimeout(e => {if(props && !props.courses.length) {props.refresh()}},500)

  return (
    <div>
      <div id="main">
        <div className="container">
          <div className="row main-row">
            <div className="col-12 large">
              <div className="card">
                <div className='bio'>
                  <p>Sign-up for up to 5 courses and we will contact you when they are available. As you finish each course you can sign up for another.</p>
                  <p>Each course is <em>$5</em> … scholarships (where someone else pays your fee) are available.</p>
                  <p>To apply for a scholarship, write why you want to take the course and why you can not afford to pay the fee.</p>
                  <p>We've also got an easily understandable guide for school leavers to ensure your legal status in the the TCIs - you can read it <b><a href="/status.pdf" target="status">here</a></b>.</p>
                </div>
              </div>
              <br/><br/><br/>
            </div>
          </div>


         <div className="row main-row">
            {props.courses.filter(c=>c.active).map(c => (
              <DrawCourse key={c.id} course={c}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses