import React from 'react'
import Axios from 'axios'
import md5 from 'md5'


import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import CloseIcon from '@mui/icons-material/Close'

const columnDefs = [
  {
    field: "username",
    headerName: "Username",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :150,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "phone",
    headerName: "Phone",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :150,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "active",
    headerName: "Active",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : '',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "applications",
    headerName: "App",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : '',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "instructors",
    headerName: "Team",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : '',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "courses",
    headerName: "Courses",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : '',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "users",
    headerName: "Users",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : '',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
]

const getUsers = async () => {
  let options = {
    method : 'GET',
    url : `https://api.tcibarefoot.org/getUsers`,
    headers : {
      'Content-type': 'application/json',
      'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
    },
  }

  let result = await Axios(options)
  return result.data.data
}

function UsersAdmin() {

  const [state, setState] = React.useState({
    users : [],
    selectedItem : null,
  })

  React.useEffect( () => { 
    async function fetchData() {
        try {
          let users = await getUsers()

          setState({users})

        } catch (err) {
          console.log(err)
        }
    }
    fetchData()
  },[])

  const openUser = row => {
    setState(prevState => ({...prevState,selectedItem:row.data}))
  }

  const close = () => {
    setState(prevState => ({...prevState,selectedItem:null}))
  }

  const handleChange = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.value
    setState(prevState => ({...prevState,selectedItem}))
  }
  
  const setChecked = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.checked
    setState(prevState => ({...prevState,selectedItem}))
  }

  const save = async () => {

    setState(prevState => ({...prevState,users:[]}))

    let data = {...state.selectedItem}

    if (data.new_passsword)
    {
      data.password = md5(data.new_passsword)
    }

    delete data.new_passsword

    let options = {
      method : 'POST',
      url : `https://api.tcibarefoot.org/saveUser`,
      data : data,
      headers : {
        'Content-type': 'application/json',
        'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
      },
    }
  
    let users = (await Axios(options)).data.data

    setState(prevState => ({...prevState,users}))

    close()
  }

  const newUser = () => {
    let selectedItem = {
      name : "",
      username : "",
      active : 1,
      password : ""
    }

    setState(prevState => ({...prevState,selectedItem}))
  }

  return (
    <>
    <div className="ag-theme-alpine agGridDiv">

      <Button autoFocus color="inherit" variant="contained" onClick={newUser}>New User</Button>

      <AgGridReact
        rowData={state.users}
        columnDefs={columnDefs}
        rowSelection="single"
        onRowDoubleClicked={openUser}
        sideBar={{
          toolPanels : ['filters'],
          closedByDefault : true,
        }}
        statusBar = {{
          statusPanels : [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' }
          ]
        }}
        >
      </AgGridReact>
    </div>


    {state.selectedItem && (<>
        <Dialog open={true} onClose={close} fullScreen>
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"/>

            <Button autoFocus color="inherit" onClick={save}>Save</Button>
          </Toolbar>
        </AppBar>

            <div className="container form">
              <div className="row main-row">
                <div className="col-12">
                  <TextField id="name" label="Name" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.name}/>
                  <TextField id="username" label="Username" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.username}/>
                  <TextField id="email" label="Email" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.email}/>
                  <TextField id="phone" label="Phone" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.phone}/>

                  <h1>Active?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="active" onChange={setChecked} checked={state.selectedItem.active}/>} label="Yes" /></h1>
                  <h1>Applications?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="applications" onChange={setChecked} checked={state.selectedItem.active}/>} label="Yes" /></h1>
                  <h1>Team?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="instructors" onChange={setChecked} checked={state.selectedItem.instructors}/>} label="Yes" /></h1>
                  <h1>Courses?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="courses" onChange={setChecked} checked={state.selectedItem.courses}/>} label="Yes" /></h1>
                  <h1>Users?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="users" onChange={setChecked} checked={state.selectedItem.users}/>} label="Yes" /></h1>

                  <TextField id="new_passsword" label={state.selectedItem.id ? "Reset Password" : "Password"} variant="outlined" required={!state.selectedItem.id} fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.new_passsword}/>
                </div>
              </div>
            </div>

      </Dialog>
      </>)}

    </>
  )
}

export default UsersAdmin