import React from 'react'
import Axios from 'axios'
import Dropzone from 'react-simple-dropzone/dist'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import CloseIcon from '@mui/icons-material/Close'

import ImagePlaceHolder from './course-image-placeholder'

const ImageFormatter = props => <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/courses/" + props.value} height={90} width={140} alt=""/>
const AvatarFormatter = props => <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + props.value.avatar} alt="" className="avatar-small"/>

const columnDefs = [
  {
    field: "image",
    headerName: "Image",
    width :180,
    autoHeight : true,
    cellRendererFramework: ImageFormatter
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "instructor.location",
    headerName: "Location",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "instructor",
    headerName: "",
    width :250,
    autoHeight : true,
    cellRendererFramework: AvatarFormatter
  },
  {
    field: "instructor.name",
    headerName: "Instructor",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :250,
    menuTabs : ['filterMenuTab'],
    autoHeight : true,
  },
  {
    field: "active",
    headerName: "Active",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : 'no',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
]

function CoursesAdmin(props) {

  const [state, setState] = React.useState({
    selectedItem : null,
    errors : {
      name : false,
      instructor : false,
      description : false,
    }
  })

  const openCourse = row => {
    setState(prevState => ({...prevState,selectedItem:row.data}))
  }

  const close = () => {
    setState(prevState => ({...prevState,selectedItem:null}))
  }

  const handleChange = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.value

    let errors = {
      name : false,
      instructor : false,
      description : false,
    }

    setState(prevState => ({...prevState,selectedItem,errors}))
  }
  
  const handleSelect = event => {
    let selectedItem = state.selectedItem
    selectedItem.instructor_id = event.target.value

    let errors = {
      name : false,
      instructor : false,
      description : false,
    }

    setState(prevState => ({...prevState,selectedItem,errors}))
  }

  const setChecked = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.checked
    setState(prevState => ({...prevState,selectedItem}))
  }

  const save = async () => {

    let formOK = true

    let data = {...state.selectedItem}
    delete data.instructor

    let errors = {
      name : false,
      instructor : false,
      description : false,
    }

    if (!data.name.length)
    {
      errors.name = true
      formOK = false
    }

    if (!data.description.length)
    {
      errors.description = true
      formOK = false
    }

    if (data.instructor_id === 0)
    {
      errors.instructor = true
      formOK = false
    }

    setState(prevState => ({...prevState,errors}))

    if (formOK)
    {
      let options = {
        method : 'POST',
        url : `https://api.tcibarefoot.org/saveCourse`,
        data : data,
        headers : {
          'Content-type': 'application/json',
          'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
        },
      }
    
     await Axios(options)
  
      props.refresh()
  
      close()
    }
  }

  const newCourse = () => {
    let selectedItem = {
      name : "",
      instructor_id : 0,
      active : 1,
      description : "",
      image : ImagePlaceHolder,
    }

    setState(prevState => ({...prevState,selectedItem}))
  }

  const uploadImage = async img => {
    let selectedItem = state.selectedItem
    selectedItem.image = img

    setState(prevState => ({...prevState,selectedItem}))
  }

  return (
    <>
    <div className="ag-theme-alpine agGridDiv">

      <Button autoFocus color="inherit" variant="contained" onClick={newCourse}>New Course</Button>

      <AgGridReact
        rowData={props.courses}
        columnDefs={columnDefs}
        rowSelection="single"
        onRowDoubleClicked={openCourse}
        sideBar={{
          toolPanels : ['filters'],
          closedByDefault : true,
        }}
        statusBar = {{
          statusPanels : [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' }
          ]
        }}
        >
      </AgGridReact>
    </div>


    {state.selectedItem && (<>
        <Dialog open={true} onClose={close} fullScreen>
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"/>

            <Button autoFocus color="inherit" onClick={save}>Save</Button>
          </Toolbar>
        </AppBar>

        <div className="container form">
          <div className="row main-row">
            <div className="col-12">
              <TextField id="name" label="Course Name" variant="outlined" fullWidth required className="form-item" onChange={handleChange} value={state.selectedItem.name} error={state.errors.name}/>
   
              <FormControl fullWidth required error={state.errors.instructor}>
                <Select id="instructor_id" label="Instructor" variant="outlined" required fullWidth className="form-item" onChange={handleSelect} value={state.selectedItem.instructor_id}>
                  <MenuItem value={0}>
                    <em>Please Select Instructor</em>
                  </MenuItem>
                  {props.instructors.map(i => (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>))}
                </Select>
              </FormControl>

              <h1>Active?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="active" onChange={setChecked} checked={state.selectedItem.active}/>} label="Yes" /></h1>
              <TextField id="description" label="Description" variant="outlined" fullWidth required className="form-item" onChange={handleChange} multiline minRows={10} maxRows={10} value={state.selectedItem.description} error={state.errors.description}/>

              <img src={state.selectedItem.image.substr(0,5) === "data:" ? state.selectedItem.image : ("https://s3.amazonaws.com/images.tcibarefoot.org/courses/" + state.selectedItem.image)} width={300} alt=""/>
              <div style={{width:300,height:200}}><Dropzone onSuccessB64={img => uploadImage(img)} preview={false} validTypes={['image/png','image/jpeg']}/></div>
            </div>
          </div>
        </div>

      </Dialog>
      </>)}

    </>
  )
}

export default CoursesAdmin