import React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import md5 from 'md5'

function Login(props) {

  const [state, setState] = React.useState({
    username : "",
    password : "",
    error : false
  })

  if (props.user) { return (<></>)}

  const handleChange = event => {
    setState(prevState => ({...prevState,[event.target.id] : event.target.value}))
  }

  const login = async event => {
    let data = {
      username : state.username,
      password : md5(state.password),
    }

    let error = !await props.login(data)

    setState(prevState => ({...prevState,error}))
  }

  return (
    <div>
      <div id="main">
        <div className="container">
          <div className="row main-row">

            <div className="col-12 large">
              <div className="card">
                <div className='form'>
                  <TextField id="username" label="Username" variant="outlined" required fullWidth className="form-item" onChange={handleChange} value={state.usernamer} error={state.error}/>
                  <TextField id="password" label="Password" variant="outlined" type="password" required fullWidth className="form-item" onChange={handleChange} value={state.usernamer} error={state.error}/>
                  <Button variant="contained" className="send-button" onClick={login}>Login</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login