function About() {

  return (
    <div>
      <div id="main">
        <div className="container">
          <div className="row main-row">

            <div className="col-12 large">
              <div className="card">
                <div className='bio'>
                  <p>Young people of the Turks and Caicos Islands today need and deserve the necessary skills to participate 
                    in the growing tourism sector as well as more information about their local environment, their flora and 
                    fauna, and their history and culture, in order to preserve, protect and promote the Turks and Caicos 
                    Islands for future generations.</p>

                    <p>We aim to supply valuable resources to support these objectives.</p>
                </div>
              </div>
            </div>

            <div className="col-12 large">
              <div className="card">
                <div className='bio'>
                  <p>The educational activities of this non-profit organisation will include, but not be limited to, 
                    the training of young people and adults in the Turks and Caicos Islands to: deepen their understanding 
                    and knowledge of the country's cultural heritage and natural environment; identify indigenous wildlife, 
                    flora and fauna, both on land and in water; acquire a variety of essential practical skills related to tourist activities:</p>

                    <ul>
                      <li>local geographical and historical knowledge of the islands, </li>
                      <li>land &amp; sea based trip preparations,</li>
                      <li>interpretive guiding,</li>
                      <li>equipment safety, handling and maintenance, </li>
                      <li>geo-location &amp; mapping, </li>
                      <li>water safety and navigation,</li>
                      <li>understanding of weather systems and patterns, </li>
                      <li>environmental impact and factors,</li>
                      <li>emergency procedures,</li>
                      <li>first Aid and life-saving skills,</li>
                      <li>risk assessments,</li>
                      <li>customer service, communication &amp; people skills, </li>
                      <li>digital content creation for promotional purposes. </li>
                    </ul>
                </div>
              </div>
            </div>

            <div className="col-12 large">
              <div className="card">
                <div className='bio'>
                  <p>These objectives are achieved by presenting structured practical courses with activities and real life training situations giving 
                    participants hands on practical experience. The courses will be open to all, including ex-offenders, who have a love of the outdoors.
                    Included will be the awarding of appropriate certifications in outdoor pursuits.</p>

                    <p>Thus participants will be enabled to engage in current and future tourist related activities to their benefit and to be 
                      part of the growth of the Turks and Caicos Islands.</p>

                    <p>We collaborate with the National Trust in supporting their objectives; and so encourage the protection of 
                      the individuality and culture of the Turks and Caicos Islands - <em>Beautiful by Nature.</em></p>
                </div>
              </div>
            </div>

            <div className="col-12 large">
              <div className="card">
                <div className='bio'>
                  <h1>Donations are gratefully received</h1>
                  <p>UnderTheTree@tciBarefoot.com</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default About