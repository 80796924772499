function Home() {

  return (
    <div>
      <div id="banner">
        <div className="white-panel">
          <h2>Barefoot Adventures</h2>
          <h2>Learning Centre</h2>
          <span>Tourism training courses in the Turks &amp; Caicos Islands</span>
        </div>
      </div>

      <div id="main">
        <div className="container">
          <div className="row main-row">
            <div className="col-12 large">
              <p>Learn some basic skills to help you get a job in Tourism …</p>
              <p>Find out more about our <em>Beautiful by Nature</em> Islands; know your environment; find out more about our Cultural Heritage; discover more about what lives on our lands and in our seas; learn practical skills for water-sports including boat safety, handling and maintaining equipment and first aid.</p>
              <p>What you learn <em>Barefoot</em> will be useful for any job … and we'll give you a certificate for each course you take and prepare you for your interview.</p>
            </div> 
          </div>
        </div>
      </div>
    </div>

  )
}

export default Home