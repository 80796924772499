import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import ApplicationsAdmin from './applications-admin'
import InstructorsAdmin from './instructors-admin'
import CoursesAdmin from './courses-admin'
import UsersAdmin from './users-admin'

function TabPanel(props) {
  const { children, value, index } = props

  return value === index && (<>{children}</>)
}


function Admin(props) {

  const [state, setState] = React.useState({
    tab : 0,
    tabList : [],
  })

  if (!props.user) return (<></>)

  let tabList = []
  let index = 0

  if (props.user.applications) {
    tabList.push({menu:<Tab label="Apply" />,item:<TabPanel value={state.tab} index={index++}><ApplicationsAdmin refresh={props.refresh}/></TabPanel>})
  }
  if (props.user.instructors) {
    tabList.push({menu:<Tab label="Team" />,item:<TabPanel value={state.tab} index={index++}><InstructorsAdmin refresh={props.refresh} instructors={props.instructors}/></TabPanel>})
  }
  if (props.user.courses) {
    tabList.push({menu:<Tab label="Courses" />,item:<TabPanel value={state.tab} index={index++}><CoursesAdmin refresh={props.refresh} courses={props.courses} instructors={props.instructors}/></TabPanel>})
  }
  if (props.user.users) {
    tabList.push({menu:<Tab label="Users" />,item:<TabPanel value={state.tab} index={index++}><UsersAdmin refresh={props.refresh}/></TabPanel>})
  }

  const handleChange = (event, tab) => {
    setState(prevState => ({...prevState,tab}))
  }

  setTimeout(e => {if(!props.instructors.length || !props.courses.length) {props.refresh()}},500)

  return (
    <div>
      <div id="main">
        <div className="container">
          <div className="row main-row">
            <div className="col-12">

            <div className="card">
              <div className='tabMenu'>
                <Tabs value={state.tab} onChange={handleChange} aria-label="admin-panel" variant="scrollable">
                  {tabList.map(t => (t.menu))}
                </Tabs>
              </div>
            </div>

            <br/><br/>

            {tabList.map(t => (t.item))}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin