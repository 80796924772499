import React from 'react'
import { Link , NavLink } from "react-router-dom"
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'

import Logo from '../images/logo.png'

import HomeIcon from '@mui/icons-material/Home'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import PersonIcon from '@mui/icons-material/Person'
import InfoIcon from '@mui/icons-material/Info'


function Header(props) {

  const [state, setState] = React.useState({
    open : false
  })

  const toggleDrawer = open => {
    setState({open})
  }

  const loggedIn = props.user !== undefined
  
  return (
    <>
      <div id="titleBar">
        <Button onClick={event => toggleDrawer(!state.open)} className="navPanel toggle"></Button>
        <span className="title">Barefoot Adventures</span>
      </div>

      <div id="header-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">

              <header id="header">
                <h1><Link to="/"><img src={Logo} alt="Barefoot Adventures" className='logo'/></Link></h1>

                <nav id="nav">
                  {!loggedIn && (
                    <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/"><HomeIcon className="nav-icon"/> Home</NavLink>
                  )}

                  <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/courses"><LocalLibraryIcon className="nav-icon"/> Courses</NavLink>
                  <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/team"><PersonIcon className="nav-icon"/> Our Team</NavLink>
                  <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/about"><InfoIcon className="nav-icon"/> About Us</NavLink>

                  {!loggedIn && (
                    <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/login"><InfoIcon className="nav-icon"/> Login</NavLink>
                  )}
                  
                  {loggedIn && (
                    <>
                      <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/admin"><InfoIcon className="nav-icon"/> Admin</NavLink>
                      <NavLink to="/" onClick={props.logout}><InfoIcon className="nav-icon"/> Logout</NavLink>
                    </>
                    )}
                </nav>
              </header>
            </div>
          </div>
        </div>
      </div>


        <Drawer
          anchor="left"
          open={state.open}
          onClose={event => toggleDrawer(false)}
        >
          <div className="popupNav">
            <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/" onClick={event => toggleDrawer(false)}><HomeIcon className="nav-icon"/> Home</NavLink>
            <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/courses" onClick={event => toggleDrawer(false)}><LocalLibraryIcon className="nav-icon"/> Courses</NavLink>
            <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/team" onClick={event => toggleDrawer(false)}><PersonIcon className="nav-icon"/> Our Team</NavLink>
            <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/about" onClick={event => toggleDrawer(false)}><InfoIcon className="nav-icon"/> About Us</NavLink>

            {!loggedIn && (
              <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/login"><InfoIcon className="nav-icon"/> Login</NavLink>
              )}
            
            {loggedIn && (
              <>
                <NavLink className={({isActive}) => isActive ? "current-page-item" : ""} to="/admin"><InfoIcon className="nav-icon"/> Admin</NavLink>
                <NavLink to="/" onClick={props.logout}><InfoIcon className="nav-icon"/> Logout</NavLink>
              </>
              )}

          </div>
        </Drawer>

    </>
  )
}

export default Header
