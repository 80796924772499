import React from 'react'
import Axios from 'axios'

import { useLocation } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import { HashLink as Link } from 'react-router-hash-link'

import Bio from './bio'

function Apply() {

  const location = useLocation()
  const { course } = location.state
  const instructor = course.instructor

  const [state, setState] = React.useState({
    name : "",
    phone : "",
    email : "",
    location : "",
    message : "",
    scholarship : false,
    sent : false,
    errors : {
      name : false,
      phone : false,
      email : false,
      location : false
    }
  })

  const handleChange = event => {
    let errors = state.errors
    errors[event.target.id] = false
    setState(prevState => ({...prevState,errors,[event.target.id] : event.target.value}))
  }

  const setChecked = event => {
    setState(prevState => ({...prevState,[event.target.id] : event.target.checked}))
  }

  const checkForm = async () => {
    let errors = state.errors

    errors.name = state.name.length === 0

    let contactError = true

    if (state.phone.length > 0) { contactError = false }
    if (/\S+@\S+\.\S+/.test(state.email)) { contactError = false }

    errors.phone = contactError
    errors.email = contactError

    errors.location = state.location.length === 0

    setState(prevState => ({...prevState,errors : errors}))
    
    let formOk = !errors.name && !contactError

    if (formOk)
    {
      let emailData = {...state}
      delete emailData.sent
      delete emailData.errors
      emailData.course_id = course.id
      emailData.scholarship = emailData.scholarship ? 1 : 0

      let options = {
        method : 'POST',
        url : `https://api.tcibarefoot.org/applyForCourse`,
        data : emailData,
        headers : {
          'Content-type': 'application/json',
          'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
        },
      }
    
     await Axios(options)

     setState(prevState => ({...prevState,sent:true}))
    }
  }

  return (
    <div>
      <div id="main">
        <div className="container">
          <div className="row main-row">
            <div className="col-12 course">
              <div className="card large">
                <div className='bio'>
                  <p>You can sign-up for up to 5 courses and we will contact you when they are available. As you finish each course you can sign up for another.</p>

                  <p>Each course is <em>$5</em> … scholarships (where someone else pays your fee) are available.</p>
                  <p>To apply for a scholarship, write why you want to take the course and why you can not afford to pay the fee in the message box below.</p>
                </div>
              </div>
            </div>
    
            <div className="col-4 col-6-medium col-12-small course">

                <div className="card">
                  <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/courses/" + course.image} alt={course.name} className="course"/>
                  <h1>{course.name}</h1>
                  <h2>{instructor.name}</h2>

                  <Bio bio={course.description}/>
                </div>

                <div className="instructor-avatar">
                  <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + instructor.avatar} alt={instructor.name} className="avatar-small"/>
                </div>
              </div>

              {!state.sent && (
                <div className="col-6 col-6-medium col-12-small">
                  <div className="card">
                    <h1>Apply for this course</h1>
                    
                    <div className="form">
                      <TextField id="name" label="Name" variant="outlined" required fullWidth className="form-item" onChange={handleChange} value={state.name} error={state.errors.name} helperText="You must provide your name"/>
                      <TextField id="email" label="Email" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.email} error={state.errors.email} />
                      <TextField id="phone" label="Phone" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.phone} error={state.errors.phone} />
                      <TextField id="location" label="Location" variant="outlined" required fullWidth className="form-item" onChange={handleChange} value={state.location} error={state.errors.location} helperText="Please tell us where you are"/>

                      <h1>Are you applying for a scholarship?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="scholarship" onChange={setChecked} checked={state.scholarship}/>} label="Yes" /></h1>
                      

                      <TextField id="message" label="Message" variant="outlined" required fullWidth multiline minRows={4} className="form-item" onChange={handleChange} value={state.message} helperText="If you need a scolarship then please tell us why here. Include anything else you'd like to tell us."/>
                      <Button variant="contained" className="send-button" onClick={checkForm}>Apply Now</Button>
                    </div>

                  </div>
                </div>
              )}


              {state.sent && (
                <div className="col-6 col-6-medium col-12-small">
                  <div className="card large">
                    <h1>Thank you for applying for the {course.name} course</h1>
                    <br/><br/>
                    <h1>We will be in touch soon</h1>
                    <br/><br/>
                    <h1>Please feel free to apply for <Link to="/courses">another course</Link></h1>
                  </div>
                </div> 
              )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Apply