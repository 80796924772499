import InstagramIcon from '@mui/icons-material/Instagram'

function Footer() {
  return (
      <div id="footer-wrapper">
        <div className="container">
          <div className="row">

            <div className="col-12">

              <section>
                <h2><a href="https://www.instagram.com/tcibarefoot/" className='social' target="instagram">Follow us <InstagramIcon/></a></h2> 
              </section>

            </div>
          </div>
        </div>
      </div>
  )
}

export default Footer