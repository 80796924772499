const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser();

function Bio(props) {

  let bio = props.bio

  let text = bio.split("\n\n")

  return (
    <div className="bio">
      {text.map((b,i)=> <p key={i}>{htmlToReactParser.parse(b)}</p>)}
    </div>
  )
}

export default Bio