import React from 'react'
import Axios from 'axios'
import Moment from 'moment'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'


import CloseIcon from '@mui/icons-material/Close'

const formatDate = dateObj => {
  return Moment(dateObj).format("DD-MM-YYYY")
}

const columnDefs = [
  {
    field: "applied",
    headerName: "Date",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :110,
    valueFormatter : obj => formatDate(obj.value),
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :120,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "instructor",
    headerName: "Instructor",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "course",
    headerName: "Course",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :120,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "phone",
    headerName: "Phone",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :120,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "location",
    headerName: "Location",
    filter: 'agSetColumnFilter',
    sortable: true, 
    width :120,
    menuTabs : ['filterMenuTab'],
  },

  { headerName: 'Scholarhip', field: 'scholarship',  width :120, valueFormatter : obj => obj.value ? 'yes' : '', filter: 'agSetColumnFilter',},
  { headerName: 'Replied', field: 'responded',  width :140, valueFormatter : obj => obj.value ? formatDate(obj.value) : '', filter: 'agSetColumnFilter',},
  { headerName: 'Accepted', field: 'accepted',  width :140, valueFormatter : obj => obj.value ? formatDate(obj.value) : '', filter: 'agSetColumnFilter',},
  { headerName: 'Attended', field: 'attended',  width :140, valueFormatter : obj => obj.value ? formatDate(obj.value) : '' , filter: 'agSetColumnFilter',},
  { headerName: 'Passed', field: 'passed',  width :140, valueFormatter : obj => obj.value ? formatDate(obj.value) : '' , filter: 'agSetColumnFilter',},
  { headerName: 'Certificate', field: 'certificate',  width :140, valueFormatter : obj => obj.value ? formatDate(obj.value) : '' , filter: 'agSetColumnFilter',},
	]

const getApplications = async () => {
  let options = {
    method : 'GET',
    url : `https://api.tcibarefoot.org/getApplications`,
    headers : {
      'Content-type': 'application/json',
      'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
    },
  }

  let result = await Axios(options)
  return result.data.data
}

function ApplicationsAdmin() {

  const [state, setState] = React.useState({
    applications : [],
    selectedItem : null,
  })

  React.useEffect( () => { 
    async function fetchData() {
        try {
          let applications = await getApplications()

          setState({applications})

        } catch (err) {
          console.log(err)
        }
    }
    fetchData()
  },[])

  const openApplication = row => {
    setState(prevState => ({...prevState,selectedItem:row.data}))
  }

  const close = () => {
    setState(prevState => ({...prevState,selectedItem:null}))
  }

  const save = async () => {

    setState(prevState => ({...prevState,applications:[]}))

    let data = {...state.selectedItem}

    if (!data.responded) { data.respondedDate = null}
    if (!data.accepted) { data.courseDate = null}
    if (data.passed) { data.attended = 1}

    delete data.instructor
    delete data.course

    let options = {
      method : 'POST',
      url : `https://api.tcibarefoot.org/saveApplication`,
      data : data,
      headers : {
        'Content-type': 'application/json',
        'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
      },
    }
  
    let applications = (await Axios(options)).data.data

    setState(prevState => ({...prevState,applications}))

    close()
  }

  const handleChange = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.value
    setState(prevState => ({...prevState,selectedItem}))
  }
  
  const setChecked = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.checked

    if (event.target.id === "responded" && event.target.checked && !selectedItem.respondedDate)
    {
      selectedItem.respondedDate = Moment()
    }

    if (event.target.id === "attended" && event.target.checked && !selectedItem.courseDate)
    {
      selectedItem.courseDate = Moment()
    }

    setState(prevState => ({...prevState,selectedItem}))
  }

  return (
    <>
      <div className="ag-theme-alpine agGridDiv">
        <AgGridReact
          rowData={state.applications}
          columnDefs={columnDefs}
          rowSelection="single"
          onRowDoubleClicked={openApplication}
          sideBar={{
            toolPanels : ['filters'],
            closedByDefault : true,
          }}
          statusBar = {{
            statusPanels : [
              { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' }
            ]
          }}
          >
        </AgGridReact>
      </div>

      {state.selectedItem && (<>
        <Dialog open={true} onClose={close} fullScreen>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={close}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"/>

              <Button autoFocus color="inherit" onClick={save}>Save</Button>
            </Toolbar>
          </AppBar>

          <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className="container form">
            <div className="row main-row">
              <div className="col-6">
                <p><h1>Instructor : {state.selectedItem.instructor}</h1></p>
                <p><h1>Course : {state.selectedItem.course}</h1></p>
                <p><h1>Applied : {formatDate(state.selectedItem.applied)}</h1></p>
                <p><h1>Scholarship needed?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="scholarship" onChange={setChecked} checked={state.selectedItem.scholarship}/>} label="Yes" /></h1></p>
               
               <p>
                <h1>Replied to applicant?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="responded" onChange={setChecked} checked={state.selectedItem.responded}/>} label="Yes" /></h1>

                <MobileDatePicker 
                  label="Replied to applicant"
                  inputFormat="DD-MM-YYYY"
                  disabled={!state.selectedItem.responded}
                  value={state.selectedItem.respondedDate}
                  onChange={dateValue => handleChange({target:{id:"respondedDate",value:dateValue.format("YYYY-MM-DD")}})}
                  renderInput={(params) => <TextField {...params} disabled={!state.selectedItem.responded}/>}/>
                </p>

                <p>
                  <h1>Accepted onto course?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="accepted" onChange={setChecked} checked={state.selectedItem.accepted}/>} label="Yes" /></h1>

                  <MobileDatePicker 
                    label="Course date"
                    inputFormat="DD-MM-YYYY"
                    disabled={!state.selectedItem.accepted}
                    value={state.selectedItem.courseDate}
                    onChange={dateValue => handleChange({target:{id:"courseDate",value:dateValue.format("YYYY-MM-DD")}})}
                    renderInput={(params) => <TextField {...params} disabled={!state.selectedItem.accepted}/>}/>
                </p>

                <h1>Attended course?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="attended" onChange={setChecked} checked={state.selectedItem.attended}/>} label="Yes" /></h1>
                <h1>Passed?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="passed" onChange={setChecked} checked={state.selectedItem.passed}/>} label="Yes" /></h1>
                <h1>Certificate Issued?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="certificate" onChange={setChecked} checked={state.selectedItem.certificate}/>} label="Yes" /></h1>
              </div>

              <div className="col-6">
                <TextField id="name" label="Name" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.name}/>
                <TextField id="location" label="Location" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.location}/>
                <TextField id="email" label="Email" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.email}/>
                <TextField id="phone" label="Phone" variant="outlined" fullWidth className="form-item" onChange={handleChange} value={state.selectedItem.phone}/>
                <TextField id="message" label="Message" variant="outlined" fullWidth multiline minRows={3} className="form-item" onChange={handleChange} value={state.selectedItem.message}/>
                <TextField id="comments" label="Notes" variant="outlined" fullWidth multiline minRows={10} className="form-item" onChange={handleChange} value={state.selectedItem.comments}/>
              </div>
            </div>
          </div>
          </LocalizationProvider>
        </Dialog>
      </>)}
    </>
  )
}

export default ApplicationsAdmin