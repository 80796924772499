import React from 'react'
import Axios from 'axios'
import cookie from 'react-cookies'

import './css/main.css'

import Header from './templates/header'
import Home from './templates/home'
import Instructors from './templates/instructors'
import Courses from './templates/courses'
import Apply from './templates/apply'
import About from './templates/about'
import Login from './templates/login'
import Admin from './templates/admin'
import Footer from './templates/footer'

import { Routes, Route, useLocation , useNavigate} from "react-router-dom"
import {useLayoutEffect} from 'react'

const ScrollToTop = ({children}) => {
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname])
  return children
}

async function getInstructors() {
  let options = {
    method : 'GET',
    url : `https://api.tcibarefoot.org/getInstructors`,
    headers : {
      'Content-type': 'application/json',
      'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
    },
  }

  let result = await Axios(options)
  return result.data.data
}

async function getCourses() {
  let options = {
    method : 'GET',
    url : `https://api.tcibarefoot.org/getCourses`,
    headers : {
      'Content-type': 'application/json',
      'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
    },
  }

  let result = await Axios(options)
  return result.data.data
}
  

function App() {
  
  const [state, setState] = React.useState({courses:[],instructors:[],user:undefined})

  React.useLayoutEffect( () => {
    async function fetchData() {
      try {
        let courses = await getCourses()
        setState(prevState => ({...prevState,courses}))

        let instructors = await getInstructors()
        setState(prevState => ({...prevState,instructors}))

        let user = cookie.load('user')
        
        // Get latest permissons - don't assume those in cookie are still correct
        let options = {
          method : 'POST',
          url : "https://api.tcibarefoot.org/getUserPermissions",
          data : {id:user.id},
          headers : {
            'Content-type': 'application/json',
            'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
          },
        }

        let permissions = (await Axios(options)).data.permissions

        for (let p in permissions)
        {
          user[p] = permissions[p]
        }

        setState(prevState => ({...prevState,user}))

      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  },[])

  const navigate = useNavigate()

  const login = async data => {
    let options = {
      method : 'POST',
      url : `https://api.tcibarefoot.org/login`,
      data : data,
      headers : {
        'Content-type': 'application/json',
        'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
      },
    }
  
   let user = (await Axios(options)).data.user

   if (user)
   {
    setState(prevState => ({...prevState,user}))
    cookie.save('user', JSON.stringify(user), { path: '/' })
    
    navigate("/admin")
   }
    return false
  }

  const logout = () => {
    setState(prevState => ({...prevState,user:undefined}))
  }

  const refresh = async () => {
    let instructors = await getInstructors()
    let courses = await getCourses()

    setState(prevState => ({...prevState,instructors,courses})) 
  }

  return (
    <ScrollToTop>
      <div id="page-wrapper">
        <Header user={state.user} logout={logout}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="team" element={<Instructors instructors={state.instructors} refresh={refresh}/>}/>
          <Route path="courses" element={<Courses courses={state.courses} refresh={refresh}/>}/>
          <Route path="about" element={<About />} />
          <Route path="apply" element={<Apply />} />
          <Route path="login" element={<Login user={state.user} login={login}/>} />
          <Route path="admin" element={<Admin user={state.user} refresh={refresh} courses={state.courses} instructors={state.instructors}/>} />
        </Routes>
        <Footer/>
      </div>
    </ScrollToTop>
  )
}

export default App
