import React from 'react'
import Axios from 'axios'
import Dropzone from 'react-simple-dropzone/dist'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import CloseIcon from '@mui/icons-material/Close'

import AvatarPlaceHolder from './avatar-placeholder'

const AvatarFormatter = props => <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + props.value} alt="" className="avatar-small"/>

const columnDefs = [
  {
    field: "avatar",
    headerName: "Avatar",
    width :180,
    autoHeight : true,
    cellRendererFramework: AvatarFormatter
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "displayName",
    headerName: "Display Name",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :200,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "location",
    headerName: "Location",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :250,
    menuTabs : ['filterMenuTab'],
    autoHeight : true,
  },
  {
    field: "courses.length",
    headerName: "Courses",
    sortable: true, 
    filter: 'agSetColumnFilter',
    width :120,
    menuTabs : ['filterMenuTab'],
  },
  {
    field: "active",
    headerName: "Active",
    sortable: true, 
    filter: 'agSetColumnFilter',
    valueFormatter : obj => obj.value ? 'yes' : 'no',
    width :110,
    menuTabs : ['filterMenuTab'],
  },
]

function InstructorsAdmin(props) {

  const [state, setState] = React.useState({
    selectedItem : null,
    errors : {
      name : false,
      displayName : false,
      bio : false,
      location : false
    }
  })

  const openInstructor = row => {
    setState(prevState => ({...prevState,selectedItem:row.data}))
  }

  const close = () => {
    setState(prevState => ({...prevState,selectedItem:null}))
  }

  const handleChange = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.value

    let errors = {
      name : false,
      displayName : false,
      bio : false,
      location : false
    }

    setState(prevState => ({...prevState,selectedItem,errors}))
  }
  
  const handleSelect = event => {
    let selectedItem = state.selectedItem
    selectedItem.location = event.target.value

    let errors = {
      name : false,
      displayName : false,
      bio : false,
      location : false
    }

    setState(prevState => ({...prevState,selectedItem,errors}))
  }

  const setChecked = event => {
    let selectedItem = state.selectedItem
    selectedItem[event.target.id] = event.target.checked
    setState(prevState => ({...prevState,selectedItem}))
  }

  const save = async () => {

    let formOK = true

    let data = {...state.selectedItem}
    delete data.courses

    let errors = {
      name : false,
      displayName : false,
      bio : false,
      location : false,
    }

    if (!data.name.length)
    {
      errors.name = true
      formOK = false
    }

    if (!data.displayName.length)
    {
      errors.displayName = true
      formOK = false
    }

    if (!data.bio.length)
    {
      errors.bio = true
      formOK = false
    }

    if (data.location === "0")
    {
      errors.location = true
      formOK = false
    }

    setState(prevState => ({...prevState,errors}))

    if (formOK)
    {
      let options = {
        method : 'POST',
        url : `https://api.tcibarefoot.org/saveInstructor`,
        data : data,
        headers : {
          'Content-type': 'application/json',
          'x-api-key': "sZ3gC2KQ0N5YuhmpatsDN5yr9ECKjJ9cUwnfEDa0",
        },
      }
    
     await Axios(options)
  
      props.refresh()
  
      close()
    }
  }

  const newInstructor = () => {
    let selectedItem = {
      name : "",
      displayName : "",
      location : "0",
      active : 1,
      bio : "",
      avatar : AvatarPlaceHolder,
      courses : []
    }

    setState(prevState => ({...prevState,selectedItem}))
  }

  const uploadImage = async img => {
    let selectedItem = state.selectedItem
    selectedItem.avatar = img

    setState(prevState => ({...prevState,selectedItem}))
  }

  return (
    <>
    <div className="ag-theme-alpine agGridDiv">

      <Button autoFocus color="inherit" variant="contained" onClick={newInstructor}>New Instructor</Button>

      <AgGridReact
        rowData={props.instructors}
        columnDefs={columnDefs}
        rowSelection="single"
        onRowDoubleClicked={openInstructor}
        sideBar={{
          toolPanels : ['filters'],
          closedByDefault : true,
        }}
        statusBar = {{
          statusPanels : [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' }
          ]
        }}
        >
      </AgGridReact>
    </div>


    {state.selectedItem && (<>
        <Dialog open={true} onClose={close} fullScreen>
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"/>

            <Button autoFocus color="inherit" onClick={save}>Save</Button>
          </Toolbar>
        </AppBar>

        <div className="container form">
          <div className="row main-row">
            <div className="col-12">
            <TextField id="name" label="Name" variant="outlined" fullWidth required className="form-item" onChange={handleChange} value={state.selectedItem.name} error={state.errors.name}/>
            <TextField id="displayName" label="Display Name" variant="outlined" fullWidth required className="form-item" onChange={handleChange} value={state.selectedItem.displayName} error={state.errors.displayName}/>
   
              <FormControl fullWidth required error={state.errors.location}>
                <Select id="location" label="Location" variant="outlined" required fullWidth className="form-item" onChange={handleSelect} value={state.selectedItem.location}>
                  <MenuItem value="0"><em>Please Select location</em></MenuItem>
                  <MenuItem value="Providenciales">Providenciales</MenuItem>
                  <MenuItem value="North Caicos">North Caicos</MenuItem>
                  <MenuItem value="South Caicos">South Caicos</MenuItem>
                  <MenuItem value="Middle Caicos">Middle Caicos</MenuItem>
                  <MenuItem value="Grand Turk">Grand Turk</MenuItem>
                  <MenuItem value="Salt Cay">Salt Cay</MenuItem>
                </Select>
              </FormControl>

              <h1>Active?  &nbsp;&nbsp; No <FormControlLabel control={ <Switch id="active" onChange={setChecked} checked={state.selectedItem.active}/>} label="Yes" /></h1>
              <TextField id="bio" label="Bio" variant="outlined" fullWidth required className="form-item" onChange={handleChange} multiline minRows={10} maxRows={10} value={state.selectedItem.bio} error={state.errors.bio}/>

              <img src={state.selectedItem.avatar.substr(0,5) === "data:" ? state.selectedItem.avatar : ("https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + state.selectedItem.avatar)} width={300} alt=""/>
              <div style={{width:300,height:200}}><Dropzone onSuccessB64={img => uploadImage(img)} preview={false} validTypes={['image/png','image/jpeg']}/></div>

              <h1>Courses</h1>
              <ul>
                {state.selectedItem.courses.map(c=> (<li>{c.name}</li>))}
              </ul>
            </div>
          </div>
        </div>

      </Dialog>
      </>)}

    </>
  )
}

export default InstructorsAdmin