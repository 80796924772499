import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import Bio from './bio'

function DrawCourses(props) {

  let instructor = props.instructor

  if (instructor.courses.length)
  {
    return (
      <>
        <h2>{instructor.displayName.replace(/([\w]+) .*/,"$1")} teaches the following courses</h2>

        <ul className="courses">
          {instructor.courses.map(c=> <li key={c.id}> <Link to={"/courses#" + c.name.toLowerCase().replace(/ /g,'-')}>{c.name}</Link></li>)}
        </ul>
      </>
    )
  }
  else
  {
    return <h2>{instructor.name.replace(/([\w]+) .*/,"$1")}'s courses will be added soon.</h2>
  }
}

function DrawInstructor(props) {
  let instructor = props.instructor
  let instructor_id = instructor.name.toLowerCase().replace(/ /g,"-")

  return (
    <>
      <div className="col-4 col-6-medium col-12-small instructor" id={instructor_id}>
        <div className="card">
          <div className="header">
            <img src={"https://s3.amazonaws.com/images.tcibarefoot.org/avatars/" + instructor.avatar} alt={instructor.displayName} className="avatar"/>
            <h1>{instructor.displayName}</h1>
            <h2>{instructor.location}</h2>
          </div>

          <Bio bio={instructor.bio}/>
          
          <DrawCourses instructor={instructor}/>
        </div>
      </div>
    </>
  )
}
 
function Instructors(props) {

  setTimeout(e => {if(!props.instructors.length) {props.refresh()}},500)
  
  return (
    <div>
      <div id="main">
        <div className="container">
         <div className="row main-row">
            {props.instructors.filter(i=>i.active).map(i => (
              <DrawInstructor key={i.id} instructor={i}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Instructors